import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-scroll";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Hamburger = () => {
  const nav = useNavigate();
  return (
    <Menu>
      <MenuButton
        fontSize={24}
        as={IconButton}
        aria-label="Options"
        icon={<FaBars />}
        variant="ghost"
      >
        Actions
      </MenuButton>
      <MenuList bg="rgba(31, 38, 46)">
        <MenuItem bg="rgba(31, 38, 46)">
          <Link to="header" smooth={true} onClick={() => nav("/")}>
            Home
          </Link>
        </MenuItem>
        <MenuItem bg="rgba(31, 38, 46)">
          {window.location.pathname === "/" ? (
            <Link to="about" smooth={true}>
              About
            </Link>
          ) : (
            <HashLink to="/#about" smooth={true}>
              About
            </HashLink>
          )}
        </MenuItem>
        <MenuItem bg="rgba(31, 38, 46)">
          {window.location.pathname === "/" ? (
            <Link to="resume" smooth={true}>
              Resume
            </Link>
          ) : (
            <HashLink to="/#resume" smooth={true}>
              Resume
            </HashLink>
          )}
        </MenuItem>
        <MenuItem bg="rgba(31, 38, 46)" onClick={() => nav("/projectsAll")}>
          <Box>Projects</Box>
        </MenuItem>
        <MenuItem bg="rgba(31, 38, 46)">
          <Link to="footer" smooth={true}>
            Contact
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default Hamburger;
