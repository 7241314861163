import React, { useState, useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import NavButton from "./NavButton";

const Row = () => {
  const nav = useNavigate();
  const [navbar, setNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [top, setTop] = useState(true);

  //navbar scroll changeBackground function
  const changeNav = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY < 100) {
        setTop(true);
      } else {
        setTop(false);
      }
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setNavbar(false);
      } else {
        // if scroll up show the navbar
        setNavbar(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    // adding the event when scroll change background
    window.addEventListener("scroll", changeNav);
  });

  return (
    <Flex
      py={6}
      px={4}
      justify="right"
      gridColumnGap={[4, 12, 12, 24]}
      position="fixed"
      w="100%"
      bg={navbar && !top && "rgba(31, 38, 46)"}
      color={!navbar && "rgba(0,0,0,0)"}
      style={{ transition: "all 0.3s" }}
    >
      <Link
        to="header"
        smooth={true}
        onClick={() => {
          window.location.pathname !== "/" && nav("/");
        }}
      >
        <NavButton label="Home" navbar={navbar} top={top} />
      </Link>
      {window.location.pathname === "/" ? (
        <Link to="about" smooth={true}>
          <NavButton label="About" navbar={navbar} top={top} />
        </Link>
      ) : (
        <HashLink to="/#about" smooth={true}>
          <NavButton label="About" navbar={navbar} />
        </HashLink>
      )}
      {window.location.pathname === "/" ? (
        <Link to="resume" smooth={true}>
          <NavButton label="Resume" navbar={navbar} />
        </Link>
      ) : (
        <HashLink to="/#resume" smooth={true}>
          <NavButton label="Resume" navbar={navbar} />
        </HashLink>
      )}
      <Box onClick={() => nav("/projectsAll")}>
        <NavButton label="Projects" navbar={navbar} />
      </Box>
      <Link to="footer" smooth={true}>
        <NavButton label="Contact" navbar={navbar} />
      </Link>
    </Flex>
  );
};

export default Row;
