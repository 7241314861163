import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  SlideFade,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaExternalLinkAlt } from "react-icons/fa";

const Experience = ({
  school,
  title,
  company,
  timeframe,
  logo,
  website,
  location,
  description,
}) => {
  const [isOpen, setIsOpen] = useState();

  return (
    <Box>
      <Box bg="#AC43FF" borderRadius="xl" p={[3, 6]}>
        <Flex justify="space-between" align="center" gridGap={2}>
          <Heading fontSize={16}>
            {title} @ {company}
          </Heading>
          <Text fontSize={24} as="button" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? "-" : "+"}
          </Text>
        </Flex>
      </Box>
      {isOpen && (
        <SlideFade in={isOpen} offsetY="-20px">
          <Box bg="rgba(172, 67, 255, .2)" borderRadius="xl" p={3} mt={2}>
            <Flex
              align={["start", "end"]}
              columnGap={2}
              flexDir={["column", "row"]}
            >
              <Image h="100px" src={logo} mb={2} bg="white" />
              <Box fontSize={12} py={[null, 2]} px={[null, 2]}>
                <Flex align="center" gridGap={2} mb={2}>
                  <Icon as={FaMapMarkerAlt} />
                  <Text>{location}</Text>
                </Flex>
                <Flex align="center" gridGap={2}>
                  <Icon as={FaExternalLinkAlt} />
                  <Text
                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => window.open(website)}
                  >
                    {website}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Text fontSize={14} color="rgba(255, 255, 255, .5)" my={2}>
              {timeframe}
            </Text>
            {school ? (
              <Box fontSize={14}>
                <Text>GPA: 3.97</Text>
                <Text>Major: Computer Science</Text>
                <Text>Minor: Biological Sciences</Text>
              </Box>
            ) : (
              <Box ml={4} fontSize={14}>
                <ul>
                  {description.map((desc) => {
                    return <li>{desc}</li>;
                  })}
                </ul>
              </Box>
            )}
          </Box>
        </SlideFade>
      )}
    </Box>
  );
};

export default Experience;
