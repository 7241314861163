import React from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  SimpleGrid,
  Icon,
  Heading,
} from "@chakra-ui/react";
import {
  FaJava,
  FaJsSquare,
  FaReact,
  FaGithub,
  FaNodeJs,
  FaPhoenixFramework
} from "react-icons/fa";
import { SiMongodb, SiFirebase, SiPostgresql, SiElixir } from "react-icons/si";
import Line1 from "../Images/llline.svg";
import Headshot from "../Images/Headshot.png";

const About = () => {
  return (
    <Flex
      id="about"
      justify="center"
      py={[20]}
      mb={24}
      bgImg={[null, null, null, Line1]}
      bgPos="center"
      bgSize="cover"
    >
      <Box w={["100%", "100%", "80%", "50%"]}>
        <Flex gridColumnGap={[4, 8]} align="end" px={8}>
          <Image
            src={Headshot}
            boxSize="200px"
            borderRadius="xl"
            shadow={"xl"}
          />
          <Box>
            <Heading fontSize={24}>Hello!</Heading>
            <Heading fontSize={24}>I'm Dani.</Heading>
          </Box>
        </Flex>
        <Box
          px={8}
          borderRadius={[0, 0, "xl"]}
          bgGradient="linear(to-b, #4743FF, #AC43FF)"
          pt={32}
          pb={20}
          my={-32}
        >
          <Text my={8}>
            I am a passionate software developer with a focus on UI and frontend
            development. I love solving problems and bringing ideas to life
            through code. When I'm not coding, you can find me traveling the
            world and experiencing different cultures. I also have a love for
            sports and staying active. In addition to my personal pursuits, I
            have a strong desire to give back to my community and serve as a
            mentor to young girls who are interested in pursuing a career in
            technology. I believe that encouraging diversity in tech is crucial
            for the industry's growth and advancement, and I am eager to play a
            role in making that a reality.
          </Text>
        </Box>
        <Box
          my={12}
          mx={6}
          bg="rgba(31, 38, 46)"
          borderRadius="xl"
          p={8}
          shadow="xl"
        >
          <Heading fontSize={20} mb={8}>
            My Tool Kit:
          </Heading>
          <SimpleGrid
            columns={[5, 10]}
            spacing={8}
            fontSize={28}
            fontWeight="extrabold"
          >
            <Icon as={FaJava} title="Java" />
            <Icon as={FaJsSquare} title="JavaScript"/>
            <Icon as={FaReact} title="React/React Native" />
            <Icon as={SiElixir} title="Elixir"/>
            <Icon as={FaPhoenixFramework} title="Phoenix" />
            <Icon as={FaNodeJs} title="Node.js" />
            <Icon as={SiMongodb} title="MongoDB" />
            <Icon as={SiFirebase} title="Firebase" />
            <Icon as={SiPostgresql} title="PostgreSQL" />
            <Icon as={FaGithub} title="GitHub" />
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  );
};

export default About;
