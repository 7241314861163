import React from "react";
import { Box, Text, Flex, SimpleGrid, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ProjectCard from "./ProjectCard";

const ProjectsRecent = () => {
  const nav = useNavigate();
  const data = require("../Utilities/projects.json");
  const recents = data.projects.slice(0, 3);

  return (
    <Flex justify="center" px={8} mb={24} id="projrec">
      <Box w={["100%", "100%", "50%"]}>
        <Heading fontSize={24} mb={12} align="center">
          Check Out My Recent Work!
        </Heading>
        <SimpleGrid columns={[1, 1, 1, 2, 2]} spacing={8}>
          {recents.map((item) => {
            return (
              <Box key={item.id}>
                <ProjectCard id={item.id} />
              </Box>
            );
          })}
          <Flex justify="right" align="end" p={2}>
            <Text
              as="button"
              onClick={() => nav("/projectsAll")}
              _hover={{ borderBottom: "1px solid white" }}
            >
              See All
            </Text>
          </Flex>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default ProjectsRecent;
