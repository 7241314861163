import React from "react";
import { Flex, useMediaQuery } from "@chakra-ui/react";

import Row from "./Row";
import Hamburger from "./Hamburger";

const Navbar = () => {
  const [isMobile] = useMediaQuery("(max-width: 700px)");

  return (
    <Flex justify="right">
      {isMobile ? (
        <Flex justify="left" position="fixed" p={4}>
          <Hamburger />
        </Flex>
      ) : (
        <Flex justify="right">
          <Row />
        </Flex>
      )}
    </Flex>
  );
};

export default Navbar;
