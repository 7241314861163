import React from "react";
import { Box, SimpleGrid, Flex, Heading } from "@chakra-ui/react";
import ProjectCard from "./ProjectCard";

const ProjectsAll = () => {
  const data = require("../Utilities/projects.json");

  return (
    <Flex justify="center" pt={[36]} px={[8, 8, 0]} minH="80vh" id="projects">
      <Box w={["100%", "100%", "50%"]}>
        <Heading fontSize={24} mb={12} align="center">
          Check Out My Work!
        </Heading>
        <SimpleGrid columns={[1, 1, 1, 2, 2]} spacing={8}>
          {data.projects.map((item) => {
            return (
              <Box key={item.id}>
                <ProjectCard id={item.id} />
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default ProjectsAll;
