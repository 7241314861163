import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Header from "./Header";
import About from "./About";
import Resume from "./Resume/Resume";
import ProjectsRecent from "./Projects/ProjectsRecent";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation;

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Box>
      <Header />
      <About />
      <Resume />
      <ProjectsRecent />
    </Box>
  );
};

export default Home;
