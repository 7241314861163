import React from "react";
import { Box, Flex, Icon, Circle, useToast, Heading } from "@chakra-ui/react";
import { FaEnvelope, FaLinkedinIn, FaGithubAlt } from "react-icons/fa";

const Footer = () => {
  const toast = useToast();
  return (
    <Flex justify="center" px={8} py={[24]} id="footer" bg="#4743FF" mt={12}>
      <Box w={["100%", "100%", "50%"]}>
        <Heading fontSize={24} mb={8} align="center">
          Let's Get In Touch!
        </Heading>
        <Flex flexDir="row" my={12} rowGap={8} columnGap={4} justify="center">
          <Circle
            size="40px"
            bg="white"
            color="#4743FF"
            _hover={{
              transform: "scale(1.2)",
              transition: "all .2s ease-in-out",
            }}
            onClick={() => {
              navigator.clipboard.writeText("danielletyler7@me.com");
              toast({
                title: "My email is now copied to your clipboard!",
                position: "bottom",
                status: "success",
                isClosable: true,
              });
            }}
          >
            <Icon as={FaEnvelope} fontSize={20} />
          </Circle>

          <Circle
            size="40px"
            bg="white"
            color="#4743FF"
            _hover={{
              transform: "scale(1.2)",
              transition: "all .2s ease-in-out",
            }}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/danielle-tyler-551164198/"
              )
            }
          >
            <Icon as={FaLinkedinIn} fontSize={20} />
          </Circle>

          <Circle
            size="40px"
            bg="white"
            color="#4743FF"
            _hover={{
              transform: "scale(1.2)",
              transition: "all .2s ease-in-out",
            }}
            onClick={() => window.open("https://github.com/danielletyler")}
          >
            <Icon as={FaGithubAlt} fontSize={20} />
          </Circle>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
