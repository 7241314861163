import React from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import Navbar from "./Components/Nav/Navbar";
import Home from "./Components/Home";
import ProjectsAll from "./Components/Projects/ProjectsAll";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/Utilities/ScrollToTop";
import Footer from "./Components/Footer";
import ProjectPage from "./Components/Projects/ProjectPage";
import theme from "./Components/Utilities/theme";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box bg="rgb(15, 20, 26)" color="white">
        <BrowserRouter>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projectsAll" element={<ProjectsAll />} />
              <Route path="/projectsPage" element={<ProjectPage />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>

        <Box h="2px" />
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
