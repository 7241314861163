import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Image,
  SimpleGrid,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

const ProjectPage = () => {
  const location = useLocation();
  const data = require("../Utilities/projects.json");
  const [toggle, setToggle] = useState(true);
  const getProjById = (id) => {
    const key = Object.keys(data.projects).find(
      (project) => data.projects[project].id === id
    );
    return data.projects[key];
  };
  const project = getProjById(location.state.id);

  return (
    <Box>
      <Box px={8} pt={36} bg="#4743FF" align="center">
        <Flex justify="center" align="center" columnGap={4}>
          <Heading fontSize={28} fontWeight="bold">
            {project.title}
          </Heading>
          <Icon
            fontSize={15}
            as={FaExternalLinkAlt}
            color="rgba(255, 255, 255, .5)"
            _hover={{ color: "white" }}
            onClick={() => window.open(project.link)}
          />
        </Flex>
        <Text color="rgba(255, 255, 255, .5)" my={4}>
          {project.tools}
        </Text>
        <Text w={["100%", "100%", "100%", "50%", "50%"]} pb={12}>
          {project.desc}
        </Text>
      </Box>
      <Flex align="center" minH="80vh" px={[8, 8, 0]} pt={8} flexDir="column">
        {project.mobile_images.length > 0 && (
          <Flex columnGap={8} pb={8}>
            <Text
              _hover={{ cursor: "pointer" }}
              textDecor={toggle && "underline"}
              onClick={() => setToggle(true)}
            >
              Desktop
            </Text>
            <Text
              _hover={{ cursor: "pointer" }}
              textDecor={!toggle && "underline"}
              onClick={() => setToggle(false)}
            >
              Mobile
            </Text>
          </Flex>
        )}

        <Box w={["100%", "100%", "80%", "50%"]} align="center">
          {toggle ? (
            <SimpleGrid columns={1} spacing={8} mb={8}>
              {project?.video !== "" && (
                <Box
                  as="video"
                  controls
                  src={require(`../../Images/${project.video}`)}
                />
              )}
              {project.images.map((e, index) => {
                return <Image key={index} src={require(`../../Images/${e}`)} />;
              })}
            </SimpleGrid>
          ) : (
            <SimpleGrid columns={[1, 2, 2]} spacing={8}>
              {project.mobile_images.map((e, index) => {
                return <Image key={index} src={require(`../../Images/${e}`)} />;
              })}
            </SimpleGrid>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ProjectPage;
