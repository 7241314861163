import React from "react";
import { Box, Flex, Text, useToast, Heading, Image } from "@chakra-ui/react";
import DT from "../Images/DT.png";

const Header = () => {
  const toast = useToast();

  return (
    <Box>
      <Flex
        flexDir={["column", "column", "column", "row"]}
        justify="center"
        align="center"
        h="100vh"
        columnGap={14}
        id="header"
      >
        <Image boxSize={["xs", "sm"]} src={DT} />
        <Box h="max-content">
          <Flex flexDir={"column"} columnGap={8}>
            <Heading
              id="heading"
              color="#A6FFFF"
              fontSize={["70px", "70px", "80px", "100px"]}
            >
              DANIELLE
            </Heading>
            <Heading
              color="#A6FFFF"
              fontSize={["70px", "80px", "80px", "100px"]}
            >
              TYLER.
            </Heading>
          </Flex>
          <Box
            borderRadius="3xl"
            px={4}
            py={2}
            my={8}
            bg="#4743FF"
            width="max-content"
            letterSpacing={"wider"}
          >
            <Text
              as="button"
              onClick={() => {
                navigator.clipboard.writeText("danielletyler7@me.com");
                toast({
                  title: "My email is now copied to your clipboard!",
                  position: "bottom",
                  status: "success",
                  isClosable: true,
                });
              }}
              fontSize={14}
            >
              LET'S CONNECT
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
