import React from "react";
import { Box, Text, Icon, Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const ProjectCard = ({ id }) => {
  const nav = useNavigate();

  const data = require("../Utilities/projects.json");
  const getProjById = (id) => {
    const key = Object.keys(data.projects).find(
      (project) => data.projects[project].id === id
    );
    return data.projects[key];
  };
  const project = getProjById(id);

  return (
    <Box
      bg="rgba(31, 38, 46)"
      shadow="2xl"
      p={4}
      borderRadius="xl"
      onClick={() =>
        nav("/projectsPage", {
          state: { id: id },
        })
      }
      minH="200px"
      maxH={[null, "250px"]}
      role="group"
      style={{ cursor: "pointer" }}
    >
      <Heading
        _groupHover={{ color: "#4743FF" }}
        fontWeight="bold"
        fontSize={20}
      >
        {project.title}
      </Heading>
      <Text fontSize={12} my={2} color="rgba(255, 255, 255, .6)">
        {project.tools}
      </Text>
      <Text fontSize={14} noOfLines={6} textOverflow="ellipsis">
        {project.desc}
      </Text>
      <Flex align="center" columnGap={2} mt={2}>
        <Text fontSize={12} fontWeight="bold" as="button">
          Read More
        </Text>
        <Icon
          _groupHover={{ color: "#4743FF" }}
          fontSize={12}
          as={FaArrowRight}
        />
      </Flex>
    </Box>
  );
};

export default ProjectCard;
