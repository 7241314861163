import React from "react";
import { Box, Flex, SimpleGrid, Heading } from "@chakra-ui/react";
import Experience from "./Experience";
import Protiviti from "../../Images/Protiviti.png";
import Breach from "../../Images/Breach.png";
import LSU from "../../Images/LSU.png";
import Revelry from "../../Images/Revelry.png";

const Resume = () => {
  return (
    <Flex justify="center" px={8} py={[24]} minH="80vh" id="resume">
      <Box w={["100%", "100%", "50%"]}>
        <Heading fontSize={24} mb={8} align="center">
          Here's Where I've Been!
        </Heading>
        <SimpleGrid columns={1} spacing={8}>
        <Experience
            school={false}
            title="Software Engineer Apprentice"
            company="Revelry"
            timeframe="March 2023 - June 2023"
            logo={Revelry}
            website="https://revelry.co"
            location="New Orleans, LA"
            description={[
              "Implemented new features, enhancement, and bug resolutions in web and mobile environments as well as respective unit tests",
              "Participated in code reviews where I communicated through github to receive and give feedback",
              "Demonstrated a strong aptitude for self-directed learning by rapidly adapting to new tech stack and continuing to seek new skills throughout the duration of the apprenticeship",
            ]}
          />
          <Experience
            school={false}
            title="Technology Consultant"
            company="Protiviti"
            timeframe="Aug 2021 - March 2023"
            logo={Protiviti}
            website="https://www.protiviti.com/us-en"
            location="Houston, TX"
            description={[
              "Conducted business and technical assessments to understand client requirements",
              "Designed, tested, and implemented customized technology solutions",
              "Demonstrated strong communication and interpersonal skills and ability to work effectively in a team-oriented environment",
            ]}
          />

          <Experience
            school={false}
            title="Software Development Intern"
            company="Breach Software"
            timeframe="June 2021 - Aug 2021"
            logo={Breach}
            website="https://breachsoftware.com/"
            location="Baton Rouge, LA"
            description={[
              "Contributed to the creation of user-friendly, high-performing, and accessible applications that met the needs of a diverse client base",
              "Demonstrated strong problem-solving and critical thinking skills, working independently and as part of a team to resolve technical challenges",
              "Collaborated with a cross-functional team of developers, designers, and stakeholders to bring new features and functionalities to life",
            ]}
          />
          <Experience
            school={true}
            title="B.S. Computer Science"
            company="Louisiana State University"
            timeframe="Aug 2017 - May 2022"
            logo={LSU}
            website="https://www.lsu.edu/"
            location="Baton Rouge, LA"
            gpa="3.97"
            minor="Biologival Sciences"
          />
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default Resume;
