import { extendTheme } from "@chakra-ui/react";

import "@fontsource/open-sans/400.css";
import "@fontsource/roboto/700.css";

const fonts = {
  body: "Open Sans",
  heading: "Roboto",
};

const colors = {
  brand: {
    default: "#f74734",
    50: "#fddcd8",
    100: "#fcb8b1",
    200: "#fa9589",
    300: "#f87162",
    400: "#f74734",
    500: "#eb200a",
    600: "#c41b08",
    700: "#9d1607",
    800: "#761005",
    900: "#3b0802",
  },
  gray: {
    50: "#f6f6f6",
    100: "#e0e0e0",
    200: "#cccccc",
    300: "#b8b8b8",
    400: "#a3a3a3",
    500: "#8f8f8f",
    600: "#7a7a7a",
    700: "#666666",
    800: "#525252",
    900: "#333333",
  },
  gradient: "#ff6034",
  gradientBg: "linear-gradient(135deg, #f74734 0%, #ff6034 100%)",
  white: "#ffffff",
  black: "#242424",
  transparent: "transparent",
};

export const theme = extendTheme({
  fonts: fonts,
  colors: colors,
});

export default theme;
