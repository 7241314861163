import React from "react";
import { Box } from "@chakra-ui/react";

const NavButton = ({ label, navbar, top }) => {
  return (
    <Box
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        width: "0%",
        height: "1px",
        bottom: 0,
        right: 0,
        backgroundColor: navbar ? "white" : "rgba(0,0,0,0)",
        transitionProperty: "width",
        transitionDuration: "0.3s",
        transitionTimingFunction: "ease-in-out",
      }}
      _hover={{
        _before: { right: "auto", width: "100%" },
        cursor: "pointer",
      }}
      _active={{ bgColor: "scale", transform: "scale(0.9)" }}
    >
      {label}
    </Box>
  );
};

export default NavButton;
